import { Button, Descriptions, Divider, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import ExtraFormItem from './ExtraFormItem';
import InvoiceFormItem from './InvoiceFormItem';

const { TextArea } = Input;

const RegistrationDetailButton = ({ record, onUpdate }) => {
  const formId = `edit_registration_${record.id}`;
  const [visible, setVisible] = useState(false);
  const initialValues = {
    invoice: record.invoice,
    invoice_name: record.invoice_name,
    note: record.note,
    extra: JSON.parse(record.extra),
  };

  const showModal = e => {
    e.stopPropagation();
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = values => {
    const data = {
      ...values,
      extra: JSON.stringify(values.extra),
    };
    onUpdate.mutateAsync({ id: record.id, data: data }).then(() => {
      setVisible(false);
    });
  };

  return (
    <>
      <Button onClick={showModal} type="text" size="small">
        {record.added ? '詳情' : '編輯'}
      </Button>
      <Modal
        title={`報名編號: ${record.id}`}
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <Button key="close" onClick={handleCancel}>
            取消
          </Button>,
          <Button key="submit" htmlType="submit" type="primary" form={formId}>
            {record.added ? '確認' : '存擋'}
          </Button>,
        ]}
      >
        <Descriptions column={1}>
          <Descriptions.Item label="報名課程場次">
            {record.course_name + record.session_name}
          </Descriptions.Item>
          <Descriptions.Item label="報名日期">
            {record.create_date}
          </Descriptions.Item>
          <Descriptions.Item label="費用">{record.fee}</Descriptions.Item>
          <Descriptions.Item label="狀態">{record.status}</Descriptions.Item>
          <Descriptions.Item label="管理者備註">
            {record.manager_note}
          </Descriptions.Item>
        </Descriptions>
        <Divider></Divider>
        <Form
          id={formId}
          preserve={false}
          layout={'vertical'}
          initialValues={initialValues}
          onFinish={onFinish}
        >
          {record.fee > 0 ? <InvoiceFormItem init={record.invoice} /> : null}
          <ExtraFormItem extra={JSON.parse(record.extra)} />
          <Form.Item label="備註（如果有特殊需求請填寫）" name="note">
            <TextArea></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RegistrationDetailButton;
